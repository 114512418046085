<template>
  <div class="case" :class="{ pd60: !isOpen }" :style="newStyle">
    <div class="case-top flex-center1 flex-center">
      <div class="flex-center column">
        <span>本月新增</span>
        <strong>{{ monthAdditionalNumber }}</strong>
      </div>
      <div class="flex-center column">
        <span>企业案例</span>
        <strong>{{ totalCaseNumber }}</strong>
      </div>
    </div>
    <div class="case-content">
      <div class="case-filter flex-center flex-around">
        <div class="all-case" @click="changeHeight">
          <div :class="{ 'ft-grey': nowTab != 0 }">
            <span class="mr5">全部案例</span><span class="crow"></span>
          </div>
        </div>
        <div @click="changeTab('1')">
          <span :class="{ 'ft-grey': nowTab != 1 }">本月新增</span>
        </div>
        <div @click="changeTab('2')">
          <span :class="{ 'ft-grey': nowTab != 2 }">与我相关</span>
        </div>
        <div @click="changeTab('3')">
          <span :class="{ 'ft-grey': nowTab != 3 }">最佳实践</span>
        </div>
      </div>
      <div class="secondary-menu" :style="{ height: newHeight }">
        <div class="modal" v-show="isOpen"></div>
        <div class="menu-items flex-start" :style="{ height: height }">
          <div class="menus-left">
            <ul>
              <li
                :class="{ active: orderTypeId === 0 }"
                @click="changeOrderTypeId(0, '')"
              >
                全部案例
              </li>
              <li
                :class="{ active: orderTypeId === item.id }"
                v-for="item in orderTypeList"
                :key="item.id"
                @click="changeOrderTypeId(item.id, item.orderClassficationName)"
              >
                {{ item.orderClassficationName }}
              </li>
            </ul>
          </div>
          <div class="menus-right">
            <ul>
              <li
                :class="{ active: problemTypeId === 0 }"
                @click="changeProblemTypeId(0, '')"
              >
                全部案例
              </li>
              <li
                :class="{ active: problemTypeId === item.id }"
                v-for="item in problemTypeList"
                :key="item.id"
                @click="
                  changeProblemTypeId(item.id, item.matterClassficationName)
                "
              >
                {{ item.matterClassficationName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="case-list"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
      >
        <ul class="wait-list" v-if="list.length > 0">
          <li
            class="pb15"
            v-for="item in list"
            :key="item.id"
            @click="$router.push(`/case-detail?id=${item.id}`)"
          >
            <div class="content-block">
              <div class="user-info flex-between mt15">
                <div class="info-left flex-start">
                  <a-avatar
                    :size="40"
                    icon="user"
                    class="mr10"
                    :src="item.reporterAvatar"
                  />
                  <div>
                    <span>{{ item.createName }}</span>
                    <p class="mt5">
                      <span class="bg-grey-color tag fz10">{{
                        item.orderClassficationName
                      }}</span>
                      <span class="bg-grey-color tag fz10">{{
                        item.matterClassficationName
                      }}</span>
                      <span class="ft-red fz10 ml5">{{
                        item.matterPropertyName
                      }}</span>
                    </p>
                  </div>
                </div>
                <div class="info-right">
                  <span
                    v-if="item.isPractice == 1"
                    class="iconfont icon-hps-appzuijiashijian fz20 ft-org"
                  ></span>
                </div>
              </div>
              <div class="order-info">
                <span class="fz12 mt15 block">{{ item.title }}</span>
                <div class="images" v-if="item.pictureUrl">
                  <img
                    v-for="img in item.pictureUrls"
                    :key="img"
                    :src="img"
                    class="mt10"
                    v-show="img"
                  />
                </div>
                <!-- <img src="" :onerror="defaultImg" class="mt10" /> -->
                <p class="fz12 mt10">{{ item.des }}</p>
                <span class="block fz10 ft-light grey mt10">{{
                  formatDate(item.reportTime)
                }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="ant-spin-container" v-else>
          <div class="ant-list-empty-text">
            <div class="ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      fill="#F5F5F5"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g fillRule="nonzero" stroke="#D9D9D9">
                      <path
                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                      ></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        fill="#FAFAFA"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import Footer from "@/components/footer/index";
import { Avatar } from "ant-design-vue";
import {
  listPagesAllCase,
  listPagesMonthAdditional,
  listPagesRelatedMe,
  listPagesPractice,
  getOrderList,
  getProblemList,
  getCaseNumber,
} from "@/utils/api";
export default {
  components: {
    Footer,
    AAvatar: Avatar,
  },
  directives: {
    infiniteScroll,
    // viewer: viewer({
    //   debug: true,
    // }),
  },
  data() {
    return {
      height: 0,
      newHeight: 0,
      isOpen: false,
      newStyle: {},
      orderTypeList: [],
      problemTypeList: [],
      allCaseList: [],
      allCaseCurrentPage: 1,
      allTotalPage: 1,
      monthList: [],
      monthCurrentPage: 1,
      monthTotalPage: 1,
      bestList: [],
      bestCurrentPage: 1,
      bestTotalPage: 1,
      pendingList: [],
      pendCurrentPage: 1,
      pendTotalPage: 1,
      list: [],
      orderTypeId: 0,
      problemTypeId: 0,
      currentPage: 1,
      totalPage: 1,
      nowTab: this.$route.query.nowTab || 0,
      searchTitle: "",
      busy: false,
      loading: false,
      tabChangeOn: false,
      monthAdditionalNumber: 0,
      totalCaseNumber: 0,
      orderClassficationName: "",
      matterClassficationName: "",
    };
  },
  created() {
    this.getCaseNumber();
    this.getOrderList();
    this.getProblemList();
  },
  methods: {
    //分类一级
    async getOrderList() {
      const { data } = await getOrderList();
      this.orderTypeList = data;
    },
    //分类二级
    async getProblemList() {
      const { data } = await getProblemList();
      this.problemTypeList = data;
    },
    //获取案例数量
    async getCaseNumber() {
      const { data } = await getCaseNumber();
      this.monthAdditionalNumber = data.monthAdditionalNumber;
      this.totalCaseNumber = data.totalCaseNumber;
    },
    //企业案例
    async listPagesAllCase(params) {
      if (this.allCaseList.length > 0 && this.tabChangeOn) {
        this.list = this.allCaseList;
        return;
      }
      const { data, currentPage, totalPage, resp_code } =
        await listPagesAllCase(params);
      if (resp_code == 0) {
        this.allCaseCurrentPage = currentPage;
        this.allTotalPage = totalPage;
        for (let i = 0; i < data.length; i++) {
          this.allCaseList.push(data[i]);
        }
        this.list = this.allCaseList;
        // this.list = [];
        this.totalPage = this.allTotalPage;
        this.loading = false;
        this.busy = false;
      }
    },
    //本月新增
    async listPagesMonthAdditional(params) {
      if (this.pendingList.length > 0 && this.tabChangeOn) {
        this.list = this.pendingList;
        return;
      }
      const { data, currentPage, totalPage, resp_code } =
        await listPagesMonthAdditional(params);
      if (resp_code == 0) {
        this.monthCurrentPage = currentPage;
        this.monthTotalPage = totalPage;
        for (let i = 0; i < data.length; i++) {
          this.pendingList.push(data[i]);
        }
        this.list = this.pendingList;
        this.totalPage = this.monthTotalPage;
        this.loading = false;
        this.busy = false;
      }
    },
    //与我相关
    async listPagesRelatedMe(params) {
      if (this.pendingList.length > 0 && this.tabChangeOn) {
        this.list = this.pendingList;
        return;
      }
      const { data, currentPage, totalPage, resp_code } =
        await listPagesRelatedMe(params);
      if (resp_code == 0) {
        this.pendCurrentPage = currentPage;
        this.pendTotalPage = totalPage;
        for (let i = 0; i < data.length; i++) {
          this.pendingList.push(data[i]);
        }
        this.list = this.pendingList;
        this.totalPage = this.pendTotalPage;
        this.loading = false;
        this.busy = false;
      }
    },
    //最佳实践
    async listPagesPractice(params) {
      if (this.bestList.length > 0 && this.tabChangeOn) {
        this.list = this.bestList;
        return;
      }
      const { data, currentPage, totalPage, resp_code } =
        await listPagesPractice(params);
      if (resp_code == 0) {
        this.bestCurrentPage = currentPage;
        this.bestTotalPage = totalPage;
        for (let i = 0; i < data.length; i++) {
          this.bestList.push(data[i]);
        }
        this.list = this.bestList;
        this.totalPage = this.bestTotalPage;
        this.loading = false;
        this.busy = false;
      }
    },
    changeHeight() {
      if (this.isOpen) {
        this.isOpen = false;
        this.height = "0px";
        this.newHeight = "0px";
        this.newStyle = {};
      } else {
        this.isOpen = true;
        this.height = "365px";
        this.newHeight = "100%";
        this.newStyle = { height: "90vh", overflow: "hidden" };
      }
    },
    changeOrderTypeId(id, orderClassficationName) {
      this.orderTypeId = id;
      this.problemTypeId = 0;
      this.nowTab = 0;
      if (orderClassficationName) {
        this.orderClassficationName = orderClassficationName;
      } else {
        this.orderClassficationName = "";
      }
    },
    changeProblemTypeId(id, matterClassficationName) {
      if (matterClassficationName) {
        this.matterClassficationName = matterClassficationName;
      } else {
        this.matterClassficationName = "";
      }
      this.problemTypeId = id;
      // this.isOpen = false;
      this.changeHeight();
      this.tabChangeOn = false;
      this.loading = true;
      this.busy = true;
      this.nowTab = 0;
      this.allCaseList = [];
      this.getData(0);
    },
    changeTab(tab) {
      this.nowTab = tab;
      this.$router.replace({
        query: {
          nowTab: tab,
        },
      });
      this.isOpen = false;
      this.height = "0px";
      this.newHeight = "0px";
      this.newStyle = {};
      this.tabChangeOn = true;
      this.loading = true;
      this.busy = true;
      this.getData(0);
    },
    getData(num) {
      switch (this.nowTab) {
        case 0:
          this.currentPage = this.allCaseCurrentPage + num;
          this.listPagesAllCase({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
            orderClassficationName: this.orderClassficationName,
            matterClassficationName: this.matterClassficationName,
          });
          break;
        case "1":
          this.currentPage = this.monthCurrentPage + num;
          this.listPagesMonthAdditional({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
        case "2":
          this.currentPage = this.pendCurrentPage + num;
          this.listPagesRelatedMe({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
        case "3":
          this.currentPage = this.bestCurrentPage + num;
          this.listPagesPractice({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
      }
    },
    handleInfiniteOnLoad() {
      if (this.busy) return;
      this.loading = true;
      this.busy = true;
      if (this.list.length === 0) {
        this.getData(0);
      } else if (this.currentPage < this.totalPage) {
        this.getData(1);
      }
    },
  },
  computed: {
    defaultImg() {
      return `this.src="${require("@/assets/img/default.png")}"`;
    },
  },
};
</script>
<style lang="less" scoped>
.case-top {
  padding: 3rem;
  height: 6.5rem;
  background: #fff;
  div {
    // margin-right: 4rem;
    padding: 0 2rem;
    font-size: 1.2rem;
    strong {
      font-size: 1.8rem;
      display: block;
      margin-top: 0.1rem;
      color: #f39800;
    }
    &:last-child {
      strong {
        color: #19a572;
      }
    }
  }
}
.case-content {
  padding: 0 3.2%;
  margin-top: 10px;
}
.case-filter,
.case-list {
  background-color: #fff;
}
.case-filter {
  padding: 1.4rem;
  border-radius: 0.8rem;
}
.crow {
  position: relative;
  top: 0.2rem;
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #121212 transparent transparent transparent;
}
.ft-grey .crow {
  border-color: #909399 transparent transparent transparent;
}
.all-case {
  //   position: relative;
}
.secondary-menu {
  // display: none;
  position: absolute;
  z-index: 999;
  left: 0;
  width: 100%;
  height: 0;
  // background-color: rgba(0, 0, 0, 0.3);
  //   border-top: 1px solid #dcdcdc;
}
.modal {
  position: absolute;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.menu-items {
  position: relative;
  z-index: 1002;
  height: 0;
  transition: height 0.5s;
  background: #fff;
  width: 100%;
  //   padding: 0 3.2%;
  > div {
    width: 50%;
    max-height: 37rem;
  }
  li {
    padding: 2rem 0;
    padding-left: 6.4%;
  }
}
.menus-left {
  background: #f3f3f3;
  overflow: hidden;
  li {
    &.active {
      color: #f39800;
      background: #fff;
    }
  }
}
.menus-right {
  overflow-y: scroll;
  li {
    &.active {
      color: #f39800;
    }
  }
}
.case-list {
  margin-top: 10px;
  border-radius: 8px;
}
.wait-list {
  li {
    padding: 0.3rem 1.5rem 1.5rem 1.5rem;
    border-bottom: 1px solid #dcdcdc;
  }
  li:last-child {
    border-bottom: 0;
  }
}
.order-info {
  img {
    width: 10.4rem;
    height: 7.5rem;
    border-radius: 3px;
    display: block;
  }
  P {
    color: #909399;
  }
}
</style>