var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer border-top"},[_c('a-row',[_c('a-col',{attrs:{"span":6},on:{"click":function($event){return _vm.sendTo('/')}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.meta !== '问题反馈'),expression:"meta !== '问题反馈'"}],staticClass:"iconfont icon-hps-apptab_icon_home_nor1"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.meta === '问题反馈'),expression:"meta === '问题反馈'"}],staticClass:"iconfont"},[_c('img',{staticStyle:{"margin-bottom":"3px"},attrs:{"src":require("@/assets/tab_icon_home_pre.svg"),"width":"20","height":"22"}})]),_c('div',{staticClass:"mttop"},[_c('span',{class:{
            ft10: true,
            grey: _vm.meta != '问题反馈',
            'ft-org': _vm.meta === '问题反馈',
          }},[_vm._v("首页")])])]),_c('a-col',{attrs:{"span":6},on:{"click":function($event){return _vm.sendTo('/case')}}},[_c('i',{staticClass:"iconfont icon-hps-appfenye",class:{ 'ft-org': _vm.meta === '案例中心' }}),_c('div',{staticClass:"mttop"},[_c('span',{staticClass:"ft10 grey",class:{
            ft10: true,
            grey: _vm.meta != '案例中心',
            'ft-org': _vm.meta === '案例中心',
          }},[_vm._v("案例")])])]),_c('a-col',{attrs:{"span":6},on:{"click":function($event){return _vm.sendTo('/message')}}},[_c('i',{staticClass:"iconfont icon-hps-appa-xiaoxi1",class:{ 'ft-org': _vm.meta === '消息中心' }}),_c('div',{staticClass:"mttop"},[_c('span',{staticClass:"ft10 grey",class:{
            ft10: true,
            grey: _vm.meta != '消息中心',
            'ft-org': _vm.meta === '消息中心',
          }},[_vm._v("消息")])])]),_c('a-col',{attrs:{"span":6},on:{"click":function($event){return _vm.sendTo('/my')}}},[_c('i',{staticClass:"iconfont icon-hps-apptab_icon_my_nor1",class:{
          'ft-org': _vm.meta === '我的',
          'icon-hps-apptab_icon_my_pre': _vm.meta == '我的',
        }}),_c('div',{staticClass:"mttop"},[_c('span',{staticClass:"ft10 grey",class:{
            ft10: true,
            grey: _vm.meta != '我的',
            'ft-org': _vm.meta === '我的',
          }},[_vm._v("我的")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }