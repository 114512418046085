<template>
  <div class="footer border-top">
    <a-row>
      <a-col :span="6" @click="sendTo('/')">
        <i
          class="iconfont icon-hps-apptab_icon_home_nor1"
          v-show="meta !== '问题反馈'"
        />
        <i v-show="meta === '问题反馈'" class="iconfont"
          ><img
            src="@/assets/tab_icon_home_pre.svg"
            width="20"
            height="22"
            style="margin-bottom: 3px"
        /></i>

        <div class="mttop">
          <span
            :class="{
              ft10: true,
              grey: meta != '问题反馈',
              'ft-org': meta === '问题反馈',
            }"
            >首页</span
          >
        </div>
      </a-col>
      <a-col :span="6" @click="sendTo('/case')"
        ><i
          class="iconfont icon-hps-appfenye"
          :class="{ 'ft-org': meta === '案例中心' }"
        />
        <div class="mttop">
          <span
            class="ft10 grey"
            :class="{
              ft10: true,
              grey: meta != '案例中心',
              'ft-org': meta === '案例中心',
            }"
            >案例</span
          >
        </div></a-col
      >
      <a-col :span="6" @click="sendTo('/message')"
        ><i
          class="iconfont icon-hps-appa-xiaoxi1"
          :class="{ 'ft-org': meta === '消息中心' }"
        />
        <div class="mttop">
          <span
            class="ft10 grey"
            :class="{
              ft10: true,
              grey: meta != '消息中心',
              'ft-org': meta === '消息中心',
            }"
            >消息</span
          >
        </div></a-col
      >
      <a-col :span="6" @click="sendTo('/my')"
        ><i
          class="iconfont icon-hps-apptab_icon_my_nor1"
          :class="{
            'ft-org': meta === '我的',
            'icon-hps-apptab_icon_my_pre': meta == '我的',
          }"
        />
        <div class="mttop">
          <span
            :class="{
              ft10: true,
              grey: meta != '我的',
              'ft-org': meta === '我的',
            }"
            class="ft10 grey"
            >我的</span
          >
        </div></a-col
      >
    </a-row>
  </div>
</template>

<script>
import { row, col } from "ant-design-vue";
export default {
  name: "hrader",
  components: {
    ARow: row,
    ACol: col,
  },
  data() {
    return {
      meta: document.title,
    };
  },
  created() {
    // console.log(this.meta);
  },
  methods: {
    sendTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  background-color: #fff;
  height: 50px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 750px;
  .iconfont {
    font-size: 20px;
  }
  .mttop {
    margin-top: -5px;
  }
}
</style>

